import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Structured logging";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "structured-logging",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#structured-logging",
        "aria-label": "structured logging permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Structured logging`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-properties-can-be-retrieved"
          }}>{`What properties can be retrieved?`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#request-properties"
            }}>{`Request properties`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#response-properties"
            }}>{`Response properties`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#client-connection-timing-properties"
            }}>{`Client connection timing properties`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#availability-of-properties"
          }}>{`Availability of properties`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#availability-of-client-timing-properties"
            }}>{`Availability of client timing properties`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#enabling-content-previews"
          }}>{`Enabling content previews`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#nested-log"
          }}>{`Nested log`}</a></p>
      </li>
    </ul>
    <p>{`Although traditional logging is a useful tool to diagnose the behavior of an application, it has its own
problem; the resulting log messages are not always machine-friendly. This section explains the Armeria API for
retrieving the information collected during request life cycle in a machine-friendly way.`}</p>
    <h2 {...{
      "id": "what-properties-can-be-retrieved",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-properties-can-be-retrieved",
        "aria-label": "what properties can be retrieved permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What properties can be retrieved?`}</h2>
    <p><a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` provides various properties recorded while handling a request:`}</p>
    <h3 {...{
      "id": "request-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#request-properties",
        "aria-label": "request properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Request properties`}</h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "13rem"
            }}>Property</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the request processing started, in microseconds since the
epoch (01-Jan-1970 00:00:00 UTC)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to process the request completely`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the byte length of the request content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestCause`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the cause of request processing failure (if any)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`sessionProtocol`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the protocol of the connection (e.g. `}<inlineCode parentName="p">{`H2C`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`serializationFormat`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the serialization format of the content (e.g. `}<inlineCode parentName="p">{`tbinary`}</inlineCode>{`, `}<inlineCode parentName="p">{`none`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`name`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the human-readable simple name of the request, such as:`}</p><ul parentName="td">
              <li parentName="ul">{`gRPC - A capitalized method name defined in
`}<inlineCode parentName="li">{`io.grpc.MethodDescriptor`}</inlineCode>{` (e.g, `}<inlineCode parentName="li">{`GetItems`}</inlineCode>{`)`}</li>
              <li parentName="ul">{`Thrift and annotated service - a method name (e.g, `}<inlineCode parentName="li">{`getItems`}</inlineCode>{`)`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
                }}>{`type://HttpService`}</a>{` - an HTTP method name`}</li>
            </ul></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`serviceName`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the human-readable name of the service that served the request,
such as:`}</p><ul parentName="td">
              <li parentName="ul">{`gRPC - a service name (e.g, `}<inlineCode parentName="li">{`com.foo.GrpcService`}</inlineCode>{`)`}</li>
              <li parentName="ul">{`Thrift - a service type (e.g, `}<inlineCode parentName="li">{`com.foo.ThriftService$AsyncIface`}</inlineCode>{` or
`}<inlineCode parentName="li">{`com.foo.ThriftService$Iface)`}</inlineCode></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
                }}>{`type://HttpService`}</a>{` and annotated service - an innermost class
name`}</li>
            </ul></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`host`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the name of the virtual host that serves the request`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestHeaders`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the HTTP headers of the request.
the header contains the method (e.g. `}<inlineCode parentName="p">{`GET`}</inlineCode>{`, `}<inlineCode parentName="p">{`POST`}</inlineCode>{`),
the path (e.g. `}<inlineCode parentName="p">{`/thrift/foo`}</inlineCode>{`),
the query (e.g. `}<inlineCode parentName="p">{`foo=bar&bar=baz`}</inlineCode>{`), the content type, etc.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestContent`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the serialization-dependent content object of the request.
`}<inlineCode parentName="p">{`ThriftCall`}</inlineCode>{` for Thrift. `}<inlineCode parentName="p">{`null`}</inlineCode>{` otherwise.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestContentPreview`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the preview of the request content`}</p></td></tr></tbody></table>
    <h3 {...{
      "id": "response-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#response-properties",
        "aria-label": "response properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response properties`}</h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "13rem"
            }}>Property</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the response processing started, in microseconds since the
epoch (01-Jan-1970 00:00:00 UTC)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to process the response completely`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the byte length of the response content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseCause`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the cause of response processing failure (if any)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`totalDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration between the request start and the response end
(i.e. response time)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseHeaders`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the HTTP headers of the response.
the header contains the statusCode (e.g. 404), the content type, etc.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseContent`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the serialization-dependent content object of the response.
`}<inlineCode parentName="p">{`ThriftReply`}</inlineCode>{` for Thrift. `}<inlineCode parentName="p">{`null`}</inlineCode>{` otherwise.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseContentPreview`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the preview of the response content`}</p></td></tr></tbody></table>
    <p>{`The `}<inlineCode parentName="p">{`totalDurationNanos`}</inlineCode>{` value is calculated differently between the client-side and the server-side.`}</p>
    <p>{`For the server-side, `}<inlineCode parentName="p">{`totalDurationNanos`}</inlineCode>{` starts when the server receives the `}<a parentName="p" {...{
        "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
      }}>{`type://RequestHeaders`}</a>{` and
ends when the server sends the response fully:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "744",
        "height": "208",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "744",
          "height": "208"
        }}></rect>{`
  `}<rect parentName="svg" {...{
          "x": "428",
          "y": "88",
          "width": "224",
          "height": "32",
          "className": "solid nofill",
          "rx": "0"
        }}></rect>{`
  `}<polygon parentName="svg" {...{
          "points": "440,100 432,104 440,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "440",
          "y1": "104",
          "x2": "456",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "458",
          "y": "108"
        }}>{`responseDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "624",
          "y1": "104",
          "x2": "640",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "640,100 648,104 640,108",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "104,36 96,40 104,44",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "104",
          "y1": "40",
          "x2": "312",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "314",
          "y": "44"
        }}>{`totalDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "456",
          "y1": "40",
          "x2": "640",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "640,36 648,40 640,44",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "104,68 96,72 104,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "104",
          "y1": "72",
          "x2": "128",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "130",
          "y": "76"
        }}>{`requestDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "288",
          "y1": "72",
          "x2": "312",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "312,68 320,72 312,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "88,108 92,96 96,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "92",
          "y1": "108",
          "x2": "92",
          "y2": "144",
          "className": "broken"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "320,108 324,96 328,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "324",
          "y1": "108",
          "x2": "324",
          "y2": "144",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "90",
          "y": "156"
        }}>{`RequestHeaders`}</text>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "156"
        }}>{`the`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "424,140 428,128 432,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "428",
          "y1": "140",
          "x2": "428",
          "y2": "176",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "410",
          "y": "188"
        }}>{`ResponseHeaders`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "648,140 652,128 656,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "652",
          "y1": "140",
          "x2": "652",
          "y2": "176",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "626",
          "y": "188"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "658",
          "y": "188"
        }}>{`last`}</text>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "156"
        }}>{`Received`}</text>{`
  `}<text parentName="svg" {...{
          "x": "234",
          "y": "156"
        }}>{`Received`}</text>{`
  `}<text parentName="svg" {...{
          "x": "338",
          "y": "156"
        }}>{`last`}</text>{`
  `}<text parentName="svg" {...{
          "x": "378",
          "y": "156"
        }}>{`frame`}</text>{`
  `}<text parentName="svg" {...{
          "x": "346",
          "y": "188"
        }}>{`Sending`}</text>{`
  `}<text parentName="svg" {...{
          "x": "562",
          "y": "188"
        }}>{`Sending`}</text>{`
  `}<text parentName="svg" {...{
          "x": "698",
          "y": "188"
        }}>{`frame`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "24",
            "x2": "652",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "24",
            "x2": "92",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "652",
            "y1": "24",
            "x2": "652",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "56",
            "x2": "652",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "324",
            "y1": "56",
            "x2": "324",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "88",
            "x2": "324",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`As you noticed, `}<inlineCode parentName="p">{`totalDurationNanos`}</inlineCode>{` is not the sum of `}<inlineCode parentName="p">{`requestDurationNanos`}</inlineCode>{` and `}<inlineCode parentName="p">{`responseDurationNanos`}</inlineCode>{`.
The sum of `}<inlineCode parentName="p">{`requestDurationNanos`}</inlineCode>{` and `}<inlineCode parentName="p">{`responseDurationNanos`}</inlineCode>{` even can be greater than `}<inlineCode parentName="p">{`totalDurationNanos`}</inlineCode>{` when
the `}<a parentName="p" {...{
        "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
      }}>{`type://ResponseHeaders`}</a>{` is sent before the service receives the `}<a parentName="p" {...{
        "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
      }}>{`type://HttpRequest`}</a>{` fully:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "664",
        "height": "192",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "664",
          "height": "192"
        }}></rect>{`
  `}<polygon parentName="svg" {...{
          "points": "104,36 96,40 104,44",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "104",
          "y1": "40",
          "x2": "312",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "314",
          "y": "44"
        }}>{`totalDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "456",
          "y1": "40",
          "x2": "640",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "640,36 648,40 640,44",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "104,68 96,72 104,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "104",
          "y1": "72",
          "x2": "192",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "194",
          "y": "76"
        }}>{`requestDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "352",
          "y1": "72",
          "x2": "440",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "440,68 448,72 440,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "296,100 288,104 296,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "296",
          "y1": "104",
          "x2": "384",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "386",
          "y": "108"
        }}>{`responseDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "552",
          "y1": "104",
          "x2": "640",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "640,100 648,104 640,108",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "280,140 284,128 288,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "284",
          "y1": "140",
          "x2": "284",
          "y2": "144",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "156"
        }}>{`:`}</text>{`
  `}<text parentName="svg" {...{
          "x": "170",
          "y": "172"
        }}>{`ResponseHeaders`}</text>{`
  `}<text parentName="svg" {...{
          "x": "106",
          "y": "172"
        }}>{`Sending`}</text>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "172"
        }}>{`before`}</text>{`
  `}<text parentName="svg" {...{
          "x": "354",
          "y": "172"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "386",
          "y": "172"
        }}>{`service`}</text>{`
  `}<text parentName="svg" {...{
          "x": "450",
          "y": "172"
        }}>{`fully`}</text>{`
  `}<text parentName="svg" {...{
          "x": "498",
          "y": "172"
        }}>{`receives`}</text>{`
  `}<text parentName="svg" {...{
          "x": "570",
          "y": "172"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "602",
          "y": "172"
        }}>{`request`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "24",
            "x2": "652",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "24",
            "x2": "92",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "652",
            "y1": "24",
            "x2": "652",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "56",
            "x2": "652",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "452",
            "y1": "56",
            "x2": "452",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "92",
            "y1": "88",
            "x2": "652",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "88",
            "x2": "284",
            "y2": "120",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "652",
            "y1": "88",
            "x2": "652",
            "y2": "120",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "120",
            "x2": "652",
            "y2": "120",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`On the other hand, `}<inlineCode parentName="p">{`totalDurationNanos`}</inlineCode>{` of the client-side contains
`}<a parentName="p" {...{
        "href": "/docs/advanced-structured-logging#client-connection-timing-properties"
      }}>{`connectionAcquisitionDuration`}</a>{`,
because a client may have to establish a new connection or acquire an existing connection from
the connection pool before sending a request:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "768",
        "height": "240",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "768",
          "height": "240"
        }}></rect>{`
  `}<rect parentName="svg" {...{
          "x": "276",
          "y": "88",
          "width": "216",
          "height": "32",
          "className": "solid nofill",
          "rx": "0"
        }}></rect>{`
  `}<polygon parentName="svg" {...{
          "points": "288,100 280,104 288,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "288",
          "y1": "104",
          "x2": "304",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "108"
        }}>{`requestDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "464",
          "y1": "104",
          "x2": "480",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "480,100 488,104 480,108",
          "className": "filled"
        }}></polygon>{`
  `}<rect parentName="svg" {...{
          "x": "532",
          "y": "120",
          "width": "224",
          "height": "32",
          "className": "solid nofill",
          "rx": "0"
        }}></rect>{`
  `}<polygon parentName="svg" {...{
          "points": "544,132 536,136 544,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "544",
          "y1": "136",
          "x2": "560",
          "y2": "136",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "562",
          "y": "140"
        }}>{`responseDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "728",
          "y1": "136",
          "x2": "744",
          "y2": "136",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "744,132 752,136 744,140",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "40,36 32,40 40,44",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "40",
          "y1": "40",
          "x2": "328",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "330",
          "y": "44"
        }}>{`totalDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "472",
          "y1": "40",
          "x2": "744",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "744,36 752,40 744,44",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "40,68 32,72 40,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "40",
          "y1": "72",
          "x2": "56",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "58",
          "y": "76"
        }}>{`connectionAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "224",
          "y1": "72",
          "x2": "240",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "240,68 248,72 240,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "272,140 276,128 280,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "276",
          "y1": "140",
          "x2": "276",
          "y2": "176",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "218",
          "y": "188"
        }}>{`RequestHeaders`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "488,140 492,128 496,140",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "492",
          "y1": "140",
          "x2": "492",
          "y2": "176",
          "className": "broken"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "528,172 532,160 536,172",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "532",
          "y1": "172",
          "x2": "532",
          "y2": "208",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "490",
          "y": "188"
        }}>{`frame`}</text>{`
  `}<text parentName="svg" {...{
          "x": "442",
          "y": "220"
        }}>{`ResponseHeaders`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "752,172 756,160 760,172",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "756",
          "y1": "172",
          "x2": "756",
          "y2": "208",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "722",
          "y": "220"
        }}>{`chunk`}</text>{`
  `}<text parentName="svg" {...{
          "x": "154",
          "y": "188"
        }}>{`Sending`}</text>{`
  `}<text parentName="svg" {...{
          "x": "354",
          "y": "188"
        }}>{`Sending`}</text>{`
  `}<text parentName="svg" {...{
          "x": "418",
          "y": "188"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "450",
          "y": "188"
        }}>{`last`}</text>{`
  `}<text parentName="svg" {...{
          "x": "370",
          "y": "220"
        }}>{`Received`}</text>{`
  `}<text parentName="svg" {...{
          "x": "578",
          "y": "220"
        }}>{`Received`}</text>{`
  `}<text parentName="svg" {...{
          "x": "650",
          "y": "220"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "682",
          "y": "220"
        }}>{`last`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "24",
            "x2": "756",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "24",
            "x2": "28",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "756",
            "y1": "24",
            "x2": "756",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "56",
            "x2": "756",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "252",
            "y1": "56",
            "x2": "252",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "88",
            "x2": "252",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <Tip mdxType="Tip">
      <p>{`In the client-side, the response timeout scheduler starts by the first of the following two events:`}</p>
      <ul>
        <li parentName="ul">{`The client sends the last frame of an `}<a parentName="li" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{`.`}</li>
        <li parentName="ul">{`The client receives a `}<a parentName="li" {...{
            "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
          }}>{`type://ResponseHeaders`}</a>{`.`}</li>
      </ul>
      <span {...{
        "className": "remark-draw remark-draw-bob-svg"
      }}><svg parentName="span" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "664",
          "height": "304",
          "className": "svgbob"
        }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
              "id": "arrow",
              "viewBox": "-2 -2 8 8",
              "refX": "4",
              "refY": "2",
              "markerWidth": "7",
              "markerHeight": "7",
              "orient": "auto-start-reverse"
            }}>{`
      `}<polygon parentName="marker" {...{
                "points": "0,0 0,4 4,2 0,0"
              }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
              "id": "diamond",
              "viewBox": "-2 -2 8 8",
              "refX": "4",
              "refY": "2",
              "markerWidth": "7",
              "markerHeight": "7",
              "orient": "auto-start-reverse"
            }}>{`
      `}<polygon parentName="marker" {...{
                "points": "0,2 2,0 4,2 2,4 0,2"
              }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
              "id": "circle",
              "viewBox": "0 0 8 8",
              "refX": "4",
              "refY": "4",
              "markerWidth": "7",
              "markerHeight": "7",
              "orient": "auto-start-reverse"
            }}>{`
      `}<circle parentName="marker" {...{
                "cx": "4",
                "cy": "4",
                "r": "2",
                "className": "filled"
              }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
              "id": "open_circle",
              "viewBox": "0 0 8 8",
              "refX": "4",
              "refY": "4",
              "markerWidth": "7",
              "markerHeight": "7",
              "orient": "auto-start-reverse"
            }}>{`
      `}<circle parentName="marker" {...{
                "cx": "4",
                "cy": "4",
                "r": "2",
                "className": "bg_filled"
              }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
              "id": "big_open_circle",
              "viewBox": "0 0 8 8",
              "refX": "4",
              "refY": "4",
              "markerWidth": "7",
              "markerHeight": "7",
              "orient": "auto-start-reverse"
            }}>{`
      `}<circle parentName="marker" {...{
                "cx": "4",
                "cy": "4",
                "r": "3",
                "className": "bg_filled"
              }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
            "className": "backdrop",
            "x": "0",
            "y": "0",
            "width": "664",
            "height": "304"
          }}></rect>{`
  `}<rect parentName="svg" {...{
            "x": "172",
            "y": "88",
            "width": "216",
            "height": "32",
            "className": "solid nofill",
            "rx": "0"
          }}></rect>{`
  `}<polygon parentName="svg" {...{
            "points": "184,100 176,104 184,108",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "184",
            "y1": "104",
            "x2": "200",
            "y2": "104",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "202",
            "y": "108"
          }}>{`requestDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "360",
            "y1": "104",
            "x2": "376",
            "y2": "104",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "376,100 384,104 376,108",
            "className": "filled"
          }}></polygon>{`
  `}<rect parentName="svg" {...{
            "x": "428",
            "y": "120",
            "width": "224",
            "height": "32",
            "className": "solid nofill",
            "rx": "0"
          }}></rect>{`
  `}<polygon parentName="svg" {...{
            "points": "440,132 432,136 440,140",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "440",
            "y1": "136",
            "x2": "456",
            "y2": "136",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "458",
            "y": "140"
          }}>{`responseDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "624",
            "y1": "136",
            "x2": "640",
            "y2": "136",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "640,132 648,136 640,140",
            "className": "filled"
          }}></polygon>{`
  `}<polygon parentName="svg" {...{
            "points": "24,36 16,40 24,44",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "24",
            "y1": "40",
            "x2": "224",
            "y2": "40",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "226",
            "y": "44"
          }}>{`totalDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "368",
            "y1": "40",
            "x2": "640",
            "y2": "40",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "640,36 648,40 640,44",
            "className": "filled"
          }}></polygon>{`
  `}<polygon parentName="svg" {...{
            "points": "24,68 16,72 24,76",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "24",
            "y1": "72",
            "x2": "40",
            "y2": "72",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "42",
            "y": "76"
          }}>{`connection`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "120",
            "y1": "72",
            "x2": "136",
            "y2": "72",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "136,68 144,72 136,76",
            "className": "filled"
          }}></polygon>{`
  `}<polygon parentName="svg" {...{
            "points": "384,140 388,128 392,140",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "388",
            "y1": "140",
            "x2": "388",
            "y2": "144",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "386",
            "y": "156"
          }}>{`:`}</text>{`
  `}<text parentName="svg" {...{
            "x": "338",
            "y": "172"
          }}>{`timeout`}</text>{`
  `}<text parentName="svg" {...{
            "x": "402",
            "y": "172"
          }}>{`scheduler`}</text>{`
  `}<text parentName="svg" {...{
            "x": "482",
            "y": "172"
          }}>{`starts`}</text>{`
  `}<text parentName="svg" {...{
            "x": "266",
            "y": "172"
          }}>{`Response`}</text>{`
  `}<text parentName="svg" {...{
            "x": "306",
            "y": "188"
          }}>{`:`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "308",
            "y1": "192",
            "x2": "308",
            "y2": "196",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "304,196 312,196 308,208",
            "className": "filled"
          }}></polygon>{`
  `}<polygon parentName="svg" {...{
            "points": "184,228 176,232 184,236",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "184",
            "y1": "232",
            "x2": "264",
            "y2": "232",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "266",
            "y": "236"
          }}>{`requestDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "424",
            "y1": "232",
            "x2": "504",
            "y2": "232",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "504,228 512,232 504,236",
            "className": "filled"
          }}></polygon>{`
  `}<polygon parentName="svg" {...{
            "points": "320,260 312,264 320,268",
            "className": "filled"
          }}></polygon>{`
  `}<line parentName="svg" {...{
            "x1": "320",
            "y1": "264",
            "x2": "408",
            "y2": "264",
            "className": "solid"
          }}></line>{`
  `}<text parentName="svg" {...{
            "x": "410",
            "y": "268"
          }}>{`responseDurationNanos`}</text>{`
  `}<line parentName="svg" {...{
            "x1": "576",
            "y1": "264",
            "x2": "640",
            "y2": "264",
            "className": "solid"
          }}></line>{`
  `}<polygon parentName="svg" {...{
            "points": "640,260 648,264 640,268",
            "className": "filled"
          }}></polygon>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
              "x1": "12",
              "y1": "24",
              "x2": "652",
              "y2": "24",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "12",
              "y1": "24",
              "x2": "12",
              "y2": "88",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "652",
              "y1": "24",
              "x2": "652",
              "y2": "56",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "12",
              "y1": "56",
              "x2": "652",
              "y2": "56",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "148",
              "y1": "56",
              "x2": "148",
              "y2": "88",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "12",
              "y1": "88",
              "x2": "148",
              "y2": "88",
              "className": "solid"
            }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
              "x1": "172",
              "y1": "216",
              "x2": "516",
              "y2": "216",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "172",
              "y1": "216",
              "x2": "172",
              "y2": "248",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "516",
              "y1": "216",
              "x2": "516",
              "y2": "248",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "172",
              "y1": "248",
              "x2": "652",
              "y2": "248",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "652",
              "y1": "248",
              "x2": "652",
              "y2": "280",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "308",
              "y1": "256",
              "x2": "308",
              "y2": "280",
              "className": "solid"
            }}></line>{`
    `}<line parentName="g" {...{
              "x1": "308",
              "y1": "280",
              "x2": "652",
              "y2": "280",
              "className": "solid"
            }}></line>{`
  `}</g>
        </svg>
      </span>
    </Tip>
    <h3 {...{
      "id": "client-connection-timing-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#client-connection-timing-properties",
        "aria-label": "client connection timing properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Client connection timing properties`}</h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "20rem"
            }}>Property</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`connectionAcquisitionStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the client started to acquire a connection, in microseconds
since the epoch (01-Jan-1970 00:00:00 UTC)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`connectionAcquisitionDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to get a connection (i.e. the total duration)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`dnsResolutionStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the client started to resolve a domain name, in microseconds
since the epoch (01-Jan-1970 00:00:00 UTC), `}<inlineCode parentName="p">{`-1`}</inlineCode>{` if DNS lookup
did not occur`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`dnsResolutionDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to resolve a domain name, `}<inlineCode parentName="p">{`-1`}</inlineCode>{` if DNS lookup
did not occur`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`socketConnectStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the client started to connect to a remote peer, in
microseconds since the epoch (01-Jan-1970 00:00:00 UTC), `}<inlineCode parentName="p">{`-1`}</inlineCode>{`
if socket connection attempt did not occur`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`socketConnectDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to connect to a remote peer, `}<inlineCode parentName="p">{`-1`}</inlineCode>{` if socket
connection attempt did not occur`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`pendingAcquisitionStartTimeMicros`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`when the client started to wait for the completion of an existing
connection attempt, in microseconds since the
epoch (01-Jan-1970 00:00:00 UTC), `}<inlineCode parentName="p">{`-1`}</inlineCode>{` if waiting did not occur`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`pendingAcquisitionDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration took to wait for the completion of an existing
connection attempt to use one connection in HTTP/2, `}<inlineCode parentName="p">{`-1`}</inlineCode>{` if
waiting did not occur`}</p></td></tr></tbody></table>
    <p>{`The connection acquisition duration is the sum of `}<inlineCode parentName="p">{`dnsResolutionDurationNanos`}</inlineCode>{`, `}<inlineCode parentName="p">{`socketConnectDurationNanos`}</inlineCode>{` and
`}<inlineCode parentName="p">{`pendingAcquisitionDurationNanos`}</inlineCode>{`. They may or may not occur depending on circumstances.
These are some of the scenarios how the total duration is composed of:`}</p>
    <ol>
      <li parentName="ol">{`Resolving a domain name and connecting to the remote peer.`}</li>
    </ol>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "816",
        "height": "144",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "816",
          "height": "144"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "26",
          "y": "44"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "40,36 32,40 40,44",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "40",
          "y1": "40",
          "x2": "208",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "210",
          "y": "44"
        }}>{`connectionAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "376",
          "y1": "40",
          "x2": "528",
          "y2": "40",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "528,36 536,40 528,44",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "26",
          "y": "76"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "40,68 32,72 40,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "40",
          "y1": "72",
          "x2": "104",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "106",
          "y": "76"
        }}>{`dnsResolution`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "208",
          "y1": "72",
          "x2": "272",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "272,68 280,72 272,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "284",
          "y1": "64",
          "x2": "284",
          "y2": "96",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "108"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "296,100 288,104 296,108",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "296",
          "y1": "104",
          "x2": "360",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "362",
          "y": "108"
        }}>{`socketConnect`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "464",
          "y1": "104",
          "x2": "528",
          "y2": "104",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "528,100 536,104 528,108",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "794",
          "y": "28"
        }}>{`#1`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "24",
            "x2": "540",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "24",
            "x2": "28",
            "y2": "32",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "540",
            "y1": "24",
            "x2": "540",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "48",
            "x2": "28",
            "y2": "64",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "56",
            "x2": "540",
            "y2": "56",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "80",
            "x2": "28",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "28",
            "y1": "88",
            "x2": "540",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "540",
            "y1": "88",
            "x2": "540",
            "y2": "120",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "112",
            "x2": "284",
            "y2": "120",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "120",
            "x2": "540",
            "y2": "120",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Waiting for the connection to be established, since there's an existing connection attempt, to use one
connection in HTTP/2. (Note that, if you create a client with an IP address, `}<inlineCode parentName="li">{`dnsResolution`}</inlineCode>{` did not
occur. Also note that, there's no `}<inlineCode parentName="li">{`socketConnect`}</inlineCode>{` because the client just waits for the connection and
uses it.)`}</li>
    </ol>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "792",
        "height": "96",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "792",
          "height": "96"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "28"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "16,20 8,24 16,28",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "16",
          "y1": "24",
          "x2": "40",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "42",
          "y": "28"
        }}>{`connectionAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "208",
          "y1": "24",
          "x2": "232",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "232,20 240,24 232,28",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "60"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "16,52 8,56 16,60",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "16",
          "y1": "56",
          "x2": "56",
          "y2": "56",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "58",
          "y": "60"
        }}>{`pendingAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "200",
          "y1": "56",
          "x2": "232",
          "y2": "56",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "232,52 240,56 232,60",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "770",
          "y": "12"
        }}>{`#2`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "244",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "4",
            "y2": "16",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "244",
            "y1": "8",
            "x2": "244",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "32",
            "x2": "4",
            "y2": "48",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "40",
            "x2": "244",
            "y2": "40",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "64",
            "x2": "4",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "72",
            "x2": "244",
            "y2": "72",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Connecting to the remote peer with the resolved IP address after the existing connection attempt is
failed.`}</li>
    </ol>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "792",
        "height": "160",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "792",
          "height": "160"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "28"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "16,20 8,24 16,28",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "16",
          "y1": "24",
          "x2": "296",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "28"
        }}>{`connectionAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "464",
          "y1": "24",
          "x2": "720",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "720,20 728,24 720,28",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "60"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "16,52 8,56 16,60",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "16",
          "y1": "56",
          "x2": "80",
          "y2": "56",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "82",
          "y": "60"
        }}>{`dnsResolution`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "184",
          "y1": "56",
          "x2": "248",
          "y2": "56",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "248,52 256,56 248,60",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "260",
          "y1": "48",
          "x2": "260",
          "y2": "80",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "258",
          "y": "92"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "272,84 264,88 272,92",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "272",
          "y1": "88",
          "x2": "296",
          "y2": "88",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "92"
        }}>{`pendingAcquisition`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "440",
          "y1": "88",
          "x2": "464",
          "y2": "88",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "464,84 472,88 464,92",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "474",
          "y": "124"
        }}>{`:`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "488,116 480,120 488,124",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "488",
          "y1": "120",
          "x2": "552",
          "y2": "120",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "554",
          "y": "124"
        }}>{`socketConnect`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "656",
          "y1": "120",
          "x2": "720",
          "y2": "120",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "720,116 728,120 720,124",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "770",
          "y": "12"
        }}>{`#3`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "732",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "4",
            "y2": "16",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "732",
            "y1": "8",
            "x2": "732",
            "y2": "40",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "32",
            "x2": "4",
            "y2": "48",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "40",
            "x2": "732",
            "y2": "40",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "64",
            "x2": "4",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "72",
            "x2": "476",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "476",
            "y1": "72",
            "x2": "476",
            "y2": "112",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "260",
            "y1": "96",
            "x2": "260",
            "y2": "104",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "260",
            "y1": "104",
            "x2": "732",
            "y2": "104",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "732",
            "y1": "104",
            "x2": "732",
            "y2": "136",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "476",
            "y1": "128",
            "x2": "476",
            "y2": "136",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "476",
            "y1": "136",
            "x2": "732",
            "y2": "136",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <h2 {...{
      "id": "availability-of-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#availability-of-properties",
        "aria-label": "availability of properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Availability of properties`}</h2>
    <p>{`Armeria handles requests and responses in a stream-oriented way, which means that some properties are revealed
only after the streams are processed to some point. For example, there's no way to know the `}<inlineCode parentName="p">{`requestLength`}</inlineCode>{`
until the request processing ends. Also, some properties related to the (de)serialization of request content,
such as `}<inlineCode parentName="p">{`serializationFormat`}</inlineCode>{` and `}<inlineCode parentName="p">{`requestContent`}</inlineCode>{`, will not be available when request processing just
started.`}</p>
    <p>{`The collected properties must be accessed via `}<a parentName="p" {...{
        "href": "type://RequestLogAccess:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLogAccess.html"
      }}>{`type://RequestLogAccess`}</a>{`, which provides a safe access to the
collected properties via the following methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`isComplete()`}</inlineCode>{` or `}<inlineCode parentName="li">{`whenComplete()`}</inlineCode>{` to check if or to get notified when all request and response
properties are available.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`isRequestComplete()`}</inlineCode>{` or `}<inlineCode parentName="li">{`whenRequestComplete()`}</inlineCode>{` to check if or to get notified when all request
properties are available.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`isAvailable(RequestLogProperty...)`}</inlineCode>{` or `}<inlineCode parentName="li">{`whenAvailable(RequestLogProperty...)`}</inlineCode>{` to check if or to get
notified when a certain set of properties are available.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.logging.RequestLog;
import com.linecorp.armeria.common.logging.RequestLogProperty;
import com.linecorp.armeria.server.ServiceRequestContext;
import com.linecorp.armeria.server.AbstractHttpService;

HttpService myService = (ctx, req) -> {
    final RequestLogAccess logAccess = ctx.log();

    logAccess.whenAvailable(RequestLogProperty.REQUEST_HEADERS)
             .thenAccept(log -> {
                 assert log.isAvailable(RequestLogProperty.REQUEST_HEADERS);
                 System.err.println("Started to handle a request: " +
                                    log.requestHeaders());
             });

    logAccess.whenComplete()
             .thenAccept(log -> {
                 assert log.isComplete();
                 System.err.println("Handled a request: " + log);
             });
    ...
}
`}</code></pre>
    <h3 {...{
      "id": "availability-of-client-timing-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#availability-of-client-timing-properties",
        "aria-label": "availability of client timing properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Availability of client timing properties`}</h3>
    <p>{`On the client side, you can also get the timing information about the related connection attempt. Unlike
request and response properties, you need to use `}<a parentName="p" {...{
        "href": "type://ClientConnectionTimings:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/ClientConnectionTimings.html"
      }}>{`type://ClientConnectionTimings`}</a>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientConnectionTimings;
import com.linecorp.armeria.client.WebClient;

WebClient client = WebClient
    .builder("http://armeria.com")
    .decorator((delegate, ctx, req) -> {
        // Can get as soon as a request is started.
        ctx.log().whenAvailable(RequestLogProperty.REQUEST_START_TIME)
           .thenAccept(log -> {
               final ClientConnectionTimings timings = ClientConnectionTimings.get(log);
               if (timings != null) {
                   System.err.println("Connection acquisition duration: " +
                                      timings.connectionAcquisitionDurationNanos());
               }
           });
        return delegate.execute(ctx, req);
    })
    .build();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`The reason why we used the static method is that the `}<a parentName="p" {...{
          "href": "type://ClientConnectionTimings:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/ClientConnectionTimings.html"
        }}>{`type://ClientConnectionTimings`}</a>{` is stored using
the attribute. See `}<a parentName="p" {...{
          "href": "/docs/advanced-custom-attributes"
        }}>{`RequestContext custom attributes`}</a>{` for more information.`}</p>
    </Tip>
    <h2 {...{
      "id": "enabling-content-previews",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#enabling-content-previews",
        "aria-label": "enabling content previews permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enabling content previews`}</h2>
    <p>{`Armeria provides the `}<inlineCode parentName="p">{`requestContentPreview`}</inlineCode>{` and `}<inlineCode parentName="p">{`responseContentPreview`}</inlineCode>{` properties in `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{`
to retrieve the textual representation of the first N bytes of the request and response content.
However, the properties are disabled by default due to performance overhead and thus they return `}<inlineCode parentName="p">{`null`}</inlineCode>{`
by default. You can enable it using `}<a parentName="p" {...{
        "href": "type://ContentPreviewingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/ContentPreviewingClient.html"
      }}>{`type://ContentPreviewingClient`}</a>{` and `}<a parentName="p" {...{
        "href": "type://ContentPreviewingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/ContentPreviewingService.html"
      }}>{`type://ContentPreviewingService`}</a>{`
decorators.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.logging.ContentPreviewingService;
import com.linecorp.armeria.server.ServerBuilder;

ServerBuilder sb = Server.builder();
...
// Enable previewing the content with the maximum length of 100 for textual content.
sb.decorator(ContentPreviewingService.newDecorator(100));
...
sb.build();
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.logging.ContentPreviewingClient;
import com.linecorp.armeria.client.WebClientBuilder;

WebClientBuilder cb = WebClient.builder();
...
cb.decorator(ContentPreviewingClient.newDecorator(100));
`}</code></pre>
    <p>{`Note that the above decorators enable the previews only for textual content
which meets one of the following cases:`}</p>
    <ul>
      <li parentName="ul">{`when its type matches `}<inlineCode parentName="li">{`text/*`}</inlineCode>{` or `}<inlineCode parentName="li">{`application/x-www-form-urlencoded`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`when its charset has been specified. e.g. application/json; charset=utf-8.`}</li>
      <li parentName="ul">{`when its subtype is `}<inlineCode parentName="li">{`xml`}</inlineCode>{` or `}<inlineCode parentName="li">{`json`}</inlineCode>{`. e.g. application/xml, application/json.`}</li>
      <li parentName="ul">{`when its subtype ends with `}<inlineCode parentName="li">{`+xml`}</inlineCode>{` or `}<inlineCode parentName="li">{`+json`}</inlineCode>{`. e.g. application/atom+xml, application/hal+json`}</li>
    </ul>
    <p>{`You can also customize the previews by specifying your own `}<a parentName="p" {...{
        "href": "type://ContentPreviewerFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/ContentPreviewerFactory.html"
      }}>{`type://ContentPreviewerFactory`}</a>{` implementation.
The following example enables the textual preview of first 100 characters for the content type of `}<inlineCode parentName="p">{`text/*`}</inlineCode>{`,
and the hex dump preview of first 100 characters for the content type of `}<inlineCode parentName="p">{`application/binary`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import io.netty.buffer.ByteBufUtil;
import com.linecorp.armeria.common.MediaType;
import com.linecorp.armeria.common.logging.ContentPreviewer;
import com.linecorp.armeria.common.logging.ContentPreviewerFactoryBuilder;

ServerBuilder sb = Server.builder();

ContentPreviewerFactoryBuilder builder = ContentPreviewerFactory.builder().maxLength(100);
builder.text((ctx, headers) -> {
    final MediaType contentType = headers.contentType();
    // Produces the textual preview when the content type is ANY_TEXT_TYPE.
    if (contentType != null && contentType.is(MediaType.ANY_TEXT_TYPE)) {
        return true;
    }
    return false;
});

// Produces the hex dump when the content type is APPLICATION_BINARY.
builder.binary(MediaType.APPLICATION_BINARY);

sb.decorator(ContentPreviewingService.newDecorator(builder.build()));
`}</code></pre>
    <p>{`You can write your own producer to change the way to make the preview, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ContentPreviewerFactoryBuilder builder = ContentPreviewerFactory.builder();
builder.binary(MediaTypeSet.of(MediaType.APPLICATION_BINARY),
               (headers, byteBuf) -> {
                   // You can use the byteBuf to produce your own way.
               });
...
ServerBuilder sb = Server.builder();
...
sb.decorator(ContentPreviewingService.newDecorator(builder.build()));
`}</code></pre>
    <h2 {...{
      "id": "nested-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nested-log",
        "aria-label": "nested log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nested log`}</h2>
    <p>{`When you retry a failed attempt, you might want to record the result of each attempt and to group them under
a single `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{`. A `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` can contain more than one child `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{`
to support this sort of use cases.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.logging.RequestLogBuilder;

RequestLogBuilder.addChild(RequestLog);
`}</code></pre>
    <p>{`If the added `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` is the first child, the request-side log of the `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` will
be propagated to the parent log. You can add as many child logs as you want, but the rest of logs would not
be affected. If you want to fill the response-side log of the parent log, please invoke:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`RequestLogBuilder.endResponseWithLastChild();
`}</code></pre>
    <p>{`This will propagate the response-side log of the last added child to the parent log. The following diagram
illustrates how a `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` with child logs looks like:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "520",
        "height": "544",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "520",
          "height": "544"
        }}></rect>{`
  `}<rect parentName="svg" {...{
          "x": "28",
          "y": "104",
          "width": "184",
          "height": "160",
          "className": "solid nofill",
          "rx": "0"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "58",
          "y": "156"
        }}>{`Request`}</text>{`
  `}<text parentName="svg" {...{
          "x": "122",
          "y": "156"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "162",
          "y": "156"
        }}>{`log`}</text>{`
  `}<text parentName="svg" {...{
          "x": "74",
          "y": "204"
        }}>{`Copied`}</text>{`
  `}<text parentName="svg" {...{
          "x": "130",
          "y": "204"
        }}>{`from`}</text>{`
  `}<text parentName="svg" {...{
          "x": "74",
          "y": "220"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "106",
          "y": "220"
        }}>{`first`}</text>{`
  `}<text parentName="svg" {...{
          "x": "154",
          "y": "220"
        }}>{`child`}</text>{`
  `}<rect parentName="svg" {...{
          "x": "28",
          "y": "296",
          "width": "184",
          "height": "160",
          "className": "solid nofill",
          "rx": "0"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "50",
          "y": "348"
        }}>{`Response`}</text>{`
  `}<text parentName="svg" {...{
          "x": "122",
          "y": "348"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "162",
          "y": "348"
        }}>{`log`}</text>{`
  `}<text parentName="svg" {...{
          "x": "74",
          "y": "396"
        }}>{`Copied`}</text>{`
  `}<text parentName="svg" {...{
          "x": "130",
          "y": "396"
        }}>{`from`}</text>{`
  `}<text parentName="svg" {...{
          "x": "74",
          "y": "412"
        }}>{`the`}</text>{`
  `}<text parentName="svg" {...{
          "x": "106",
          "y": "412"
        }}>{`last`}</text>{`
  `}<text parentName="svg" {...{
          "x": "146",
          "y": "412"
        }}>{`child`}</text>{`
  `}<text parentName="svg" {...{
          "x": "26",
          "y": "44"
        }}>{`RequestLog`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "172"
        }}>{`Child`}</text>{`
  `}<text parentName="svg" {...{
          "x": "330",
          "y": "172"
        }}>{`#1`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "224,196 216,200 224,204",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "204"
        }}>{`Request`}</text>{`
  `}<text parentName="svg" {...{
          "x": "362",
          "y": "204"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "402",
          "y": "204"
        }}>{`log`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "244",
          "y1": "208",
          "x2": "244",
          "y2": "416",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "236"
        }}>{`:`}</text>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "236"
        }}>{`Response`}</text>{`
  `}<text parentName="svg" {...{
          "x": "370",
          "y": "236"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "410",
          "y": "236"
        }}>{`log`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "284"
        }}>{`...`}</text>{`
  `}<text parentName="svg" {...{
          "x": "362",
          "y": "316"
        }}>{`.`}</text>{`
  `}<text parentName="svg" {...{
          "x": "362",
          "y": "332"
        }}>{`.`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "364"
        }}>{`Child`}</text>{`
  `}<text parentName="svg" {...{
          "x": "330",
          "y": "364"
        }}>{`#N`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "396"
        }}>{`:`}</text>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "396"
        }}>{`Request`}</text>{`
  `}<text parentName="svg" {...{
          "x": "362",
          "y": "396"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "402",
          "y": "396"
        }}>{`log`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "224,420 216,424 224,428",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "428"
        }}>{`Response`}</text>{`
  `}<text parentName="svg" {...{
          "x": "370",
          "y": "428"
        }}>{`side`}</text>{`
  `}<text parentName="svg" {...{
          "x": "410",
          "y": "428"
        }}>{`log`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "244",
          "y1": "432",
          "x2": "244",
          "y2": "480",
          "className": "broken"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "298",
          "y": "108"
        }}>{`Child`}</text>{`
  `}<text parentName="svg" {...{
          "x": "346",
          "y": "108"
        }}>{`RequestLogs`}</text>{`
  `}<text parentName="svg" {...{
          "x": "314",
          "y": "124"
        }}>{`e.g.`}</text>{`
  `}<text parentName="svg" {...{
          "x": "354",
          "y": "124"
        }}>{`retries`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "8",
            "y1": "0",
            "x2": "4",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "508",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "4",
            "y2": "512",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "504",
            "y1": "0",
            "x2": "508",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "508",
            "y1": "8",
            "x2": "508",
            "y2": "512",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "0",
            "y1": "512",
            "x2": "8",
            "y2": "528",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "520",
            "x2": "508",
            "y2": "520",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "512",
            "y1": "512",
            "x2": "504",
            "y2": "528",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "248",
            "y1": "64",
            "x2": "244",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "244",
            "y1": "72",
            "x2": "484",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "244",
            "y1": "72",
            "x2": "244",
            "y2": "192",
            "className": "broken"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "480",
            "y1": "64",
            "x2": "484",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "484",
            "y1": "72",
            "x2": "484",
            "y2": "480",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "152",
            "x2": "460",
            "y2": "152",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "152",
            "x2": "268",
            "y2": "192",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "460",
            "y1": "152",
            "x2": "460",
            "y2": "296",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "208",
            "x2": "268",
            "y2": "296",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "264",
            "x2": "460",
            "y2": "264",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "296",
            "x2": "460",
            "y2": "296",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "184",
            "x2": "444",
            "y2": "184",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "184",
            "x2": "284",
            "y2": "224",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "444",
            "y1": "184",
            "x2": "444",
            "y2": "248",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "224",
            "y1": "200",
            "x2": "284",
            "y2": "200",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "216",
            "x2": "444",
            "y2": "216",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "240",
            "x2": "284",
            "y2": "248",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "248",
            "x2": "444",
            "y2": "248",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "344",
            "x2": "460",
            "y2": "344",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "344",
            "x2": "268",
            "y2": "416",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "460",
            "y1": "344",
            "x2": "460",
            "y2": "456",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "432",
            "x2": "268",
            "y2": "456",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "456",
            "x2": "460",
            "y2": "456",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "376",
            "x2": "444",
            "y2": "376",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "376",
            "x2": "284",
            "y2": "384",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "444",
            "y1": "376",
            "x2": "444",
            "y2": "440",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "400",
            "x2": "284",
            "y2": "440",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "408",
            "x2": "444",
            "y2": "408",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "224",
            "y1": "424",
            "x2": "284",
            "y2": "424",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "440",
            "x2": "444",
            "y2": "440",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "240",
            "y1": "480",
            "x2": "248",
            "y2": "496",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "244",
            "y1": "488",
            "x2": "484",
            "y2": "488",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "488",
            "y1": "480",
            "x2": "480",
            "y2": "496",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`You can retrieve the child logs using `}<inlineCode parentName="p">{`RequestLog.children()`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final RequestContext ctx = ...;
ctx.log().whenComplete().thenAccept(log -> {
    if (!log.children().isEmpty()) {
        System.err.println("A request finished after " + log.children().size() + " attempt(s): " + log);
    } else {
        System.err.println("A request is done: " + log);
    }
});
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` is a good example that leverages this feature.
See `}<a parentName="p" {...{
        "href": "/docs/client-retry#retryingclient-with-logging"
      }}>{`RetryingClient with logging`}</a>{` for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      